import React from 'react';
import BlogArticle from 'components/blog/blogArticle';
import { Post } from 'components/blog/content/styled';
import { authorPatryk as author } from 'data/authors';
import cover from 'img/blog/covers/rest-api.png';
import img from 'img/help/rest-api/rest-api1.png';

const Content = () => {
  return (
    <Post>
      <p>We&apos;re happy to announce a Beta version of LiveSession&apos;s REST API!</p>
      <p>
        From now, you&apos;ll be able to retrieve information about your data programmatically. You
        can, for example, use the API for a deeper analysis of the data collected by LiveSession.
      </p>
      <p>
        To get started, go to Settings &gt; API Tokens and then click the &quot;Create Token&quot;
        button.
      </p>
      <img src={img} alt="Segments notifications - preview" title="Add to summary" />
      <p>
        If you need more technical details, take a look at{' '}
        <a
          target="_blank"
          href={`${process.env.GATSBY_LS_DEVELOPERS_URL}/rest-api/introduction/`}
          rel="noreferrer"
        >
          LiveSession Developers
        </a>
        .
      </p>
    </Post>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'Rest API is live!',
  url: '/blog/rest-api',
  description: `Introducing segments notifications! Get a daily or weekly email summary of segments that you follow.`,
  author,
  img: cover,
  imgSocial: cover,
  date: '2021-06-29',
  category: '',
  group: 'updates',
  timeToRead: 1,
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    img={frontmatter.img}
    imgSocial={process.env.GATSBY_HOST + frontmatter.img}
    group={frontmatter.group}
    timeToRead={frontmatter.timeToRead}
  />
);
